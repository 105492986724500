// create blog post template page for gatsby-node.js using contentful data
import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Header,
  Article,
  SocialMediaLinks,
  Footer,
  BlogLinks,
} from "../components";

const BlogPostStyles = styled.div`
  padding: 80px;
`;

// eslint-disable-next-line react/prop-types
export default function BlogPostTemplate({ data, pageContext }) {
  const { blogPost } = data;
  return (
    <main>
      <Header />
      <BlogPostStyles>
        <Article
          title={blogPost.title}
          date={blogPost.date}
          body={blogPost.body}
        />
        <SocialMediaLinks slug={blogPost.slug} />
        <BlogLinks prev={pageContext.prev} next={pageContext.next} />
      </BlogPostStyles>
      <Footer />
    </main>
  );
}

BlogPostTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query ($slug: String!) {
    blogPost: contentfulBlogPost(slug: { eq: $slug }) {
      title
      date
      slug
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(placeholder: BLURRED)
            file {
              contentType
              url
              fileName
            }
          }
          ... on ContentfulBlogPost {
            contentful_id
            __typename
            title
            slug
          }
        }
      }
    }
  }
`;
